.wrapper {
    width: 100%;
    padding: 35px 0;
    .container {
        max-width: 1280px;
        margin: 25px auto 0px auto;
        padding: 0 35px;
        .title {
            font-size: 32px;
            text-align: center;
            color: #242c7d;
            font-weight: bold;
        }
        .videoContainer {
            display: flex;
            justify-content: center;
            margin-top: 35px;
            &>div {
                margin: 0 35px;
            }
        }
    }
}

@media (max-width: 800px) {
    .wrapper {
        .container {
            .videoContainer {
                display: block;
                &>div {
                    margin: 25px auto;
                    width: 100% !important;
                    max-width: 320px;
                }
            }
        }
    }
}