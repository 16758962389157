.container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 35px;
    font-size: 20px;
    .title {
        font-size: 32px;
        text-align: center;
        padding-top: 30px;
        margin-bottom: 40px;
        color: #242c7d;
        text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    }
    .items {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        color: #555;
        .studyItem {
            display: flex;
            flex-grow: 1;
            width: 33%;
            margin-bottom: 35px;
            .icon {
                font-size: 55px;
                margin-right: 25px;
                color: #00B294;
            }
        }
    }
}

@media (max-width: 800px) {
    .container {
        .items {
            .studyItem {
                width: 100%;
            }
        }
    }
}
