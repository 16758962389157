.wrapper {
    position: relative;
    width: 100%;
    background-color: #3d4454;
    padding: 35px 0;
    color: #fff;
    .container {
        max-width: 1280px;
        margin: 0 auto;
        padding: 0 35px;
        .copyright {
            text-align: center;
            font-size: 13px;
            letter-spacing: 1px;
            & > div {
                margin-bottom: 6px;
            }
            a {
                color: #fff;
            }
        }
        .socialContainer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .mail {
                .text {
                    margin-bottom: 5px;
                    font-weight: bold;
                }
                .contactItem {
                    display: flex;
                    align-items: center;
                    a {
                        color: #fff;
                        margin-left: 8px;
                        transition: 0.2s;
                        &:hover {
                            opacity: 0.6;
                            transition: 0.3s;
                        }
                    }
                }
            }
            .banner {
                img {
                    width: 220px;
                }
            }
            .networks {
                font-size: 26px;
                a {
                    margin-left: 15px;
                    cursor: pointer;
                    color: #fff;
                    text-decoration: none;
                    img {
                        width: 26px;
                    }
                    &:hover {
                        opacity: 0.6;
                        transition: 0.3s;
                    }
                }
            }
        }
    }
}

@media (max-width: 800px) {
    .wrapper {
        .container {
            .copyright {
                text-align: left;
            }
            .socialContainer {
                display: block;
                .networks {
                    margin-top: 25px;
                    a {
                        margin-left: unset;
                        margin-right: 15px;
                    }
                }
                .banner {
                    margin-top: 25px;
                }
            }
        }
    }
}