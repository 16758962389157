.wrapprer {
    width: 100%;
    background: #eff3f8 url(../../assets/images/section1_bg.webp) top no-repeat;
    padding: 35px 0;
    margin-top: 35px;
    .container {
        max-width: 1280px;
        margin: 15px auto 5px auto;
        padding: 0 35px;
        .title {
            font-size: 32px;
            text-align: center;
            color: #242c7d;
            font-weight: bold;
        }
        .text {
            margin: 35px auto 0 auto;
            max-width: 750px;
            text-align: justify;
            font-size: 17.5px;
            p {
                position: relative;
                &::before {
                    content: "\2022";
                    position: absolute;
                    font-size: 18px;
                    left: -20px;
                    color: #242c7d;
                }
            }
        }
    }
}