.container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    .circleButton {
        position: absolute;
        right: 45px;
        bottom: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        font-size: 32px;
        background-color: rgb(53, 174, 227);
        color: #fff;
        box-shadow: 0 0 0 rgba(204,169,44, 0.4);
        animation: pulse 2s infinite;
        transition: 0.15s;
        cursor: pointer;
    }
    @-webkit-keyframes pulse {
        0% {
            -webkit-box-shadow: 0 0 0 0 rgba(53, 174, 227, 0.4);
        }
        70% {
            -webkit-box-shadow: 0 0 0 10px rgba(53, 174, 227, 0);
        }
        100% {
            -webkit-box-shadow: 0 0 0 0 rgba(53, 174, 227, 0);
        }
      }
    @keyframes pulse {
        0% {
          box-shadow: 0 0 0 0 rgba(53, 174, 227, 0.4);
        }
        70% {
            box-shadow: 0 0 0 10px rgba(53, 174, 227, 0);
        }
        100% {
            box-shadow: 0 0 0 0 rgba(53, 174, 227, 0);
        }
    }
    .form {
        width: 100%;
        padding: 25px;
        box-sizing: border-box;
        // background-color: rgba(53, 174, 227, 0.9);
        background-color: #ededf0;
        // color: #fff;
        color: #555;
        font-size: 18px;
        box-shadow: 0px 0px 11px 4px rgba(0, 0, 0, 0.1);
        .title {
            font-size: 28px;
            text-align: center;
            letter-spacing: 1px;
            a {
                color: #00B294;
            }
        }
        .inputs {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 800px;
            margin: 15px auto;
            input {
                width: 75%;
                height: 45px;
                border: none;
                box-sizing: border-box;
                padding: 0 25px;
                color: #555;
                font-size: 18px;
                &:focus {
                    outline: none;
                }
                &::placeholder {
                    color: #9a9a9a
                }
            }
            button {
                width: 25%;
                height: 45px;
                border: none;
                background-color: rgba(53, 174, 227, 1);
                color: #fff;
                font-size: 20px;
                font-weight: 600;
                box-sizing: border-box;
                cursor: pointer;
                transition: 0.2s;
                svg {
                    display: none;
                }
                &:focus {
                    outline: none;
                }
                &:hover {
                    opacity: 0.7;
                    transition: 0.3s;
                }
            }
            .errMessage {
                position: absolute;
                color: #ff5722;
                bottom: -20px;
                left: 5px;
                font-size: 13.5px;
            }
            .successMessage {
                position: absolute;
                bottom: -20px;
                left: 5px;
                font-size: 13.5px;
                color: #72c02d;
            }
        }
        .close {
            position: absolute;
            right: 15px;
            top: 5px;
            font-size: 32px;
            cursor: pointer;
            svg {
                path {
                    stroke: #ff582a;
                }
            }
        }
    }
}

@media (max-width: 800px) {
    .container {
        .form {
            .inputs {
                button {
                    span {
                        display: none;
                    }
                    svg {
                        display: initial;
                    }
                }
            }
        }
    }
}