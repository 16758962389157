
@import '../../assets//constants/colors.scss';

.container {
    max-width: 900px;
    margin: 0 auto;
    padding: 25px 35px;
    .title {
        width: 100%;
        position: relative;
        margin: 40px auto 25px auto;
        font-size: 32px;
        text-align: center;
        color: #242c7d;
        text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    }
    .list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .item {
            display: flex;
            flex-direction: column;
            width: 280px;
            margin: 0 auto;
            margin-bottom: 25px;
            border-radius: 6px;
            overflow: hidden;
            box-shadow: 0px 0px 11px 4px rgba(0,0,0,0.06);
            box-sizing: border-box;
            transition: 0.2s;
            &.active {
                background: #eff3f8 url(../../assets/images/section1_bg.webp) top no-repeat;
                box-shadow: none;
                background-size: contain;
                border: solid 2px rgba(0, 178, 148, 0.12);
                transition: 0.3s;
            }
            .header {
                border-bottom: solid 1px #f1f1f1;
                padding-bottom: 15px;
                text-align: center;
                .name {
                    position: relative;
                    margin-top: 15px;
                    color: #00B294;
                    font-size: 24px;
                    font-weight: 600;
                    &:after {
                        position: absolute;
                        content: "";
                        bottom: -6px;
                        left: calc(50% - 30px);
                        width: 60px;
                        height: 2px;
                        background-color: #00B294;
                    }
                }
                .price {
                    font-weight: 700;
                    font-size: 32px;
                    margin-top: 15px;
                    &.crossLine {
                        position: relative;
                        font-size: 22px;
                        text-decoration: line-through #ff582a;
                    }
                }
            }
            .about {
                font-size: 15px;
                text-decoration: underline;
                color: #00B294;
                margin: 25px;
                cursor: pointer;
                user-select: none;
            }
        }
        .offer {
            width: 100%;
            box-shadow: 0px 0px 11px 4px rgba(0,0,0,0.06);
            padding: 25px;
            border-radius: 6px;
            border-left: solid 6px $secondary;
            background: #f8efef70 url(../../assets/images/section1_bg.webp) top no-repeat;
            .offerTitle {
                font-size: 24px;
                font-weight: 600;
                color: $secondary;
            }
            .seperatedFlexItems {
                display: flex;
                .dottedList {
                    flex: 0 0 200px;
                    margin-right: 25px;
                }
                .description {
                    flex: 1;
                    box-shadow: 0px 0px 11px 4px rgba(0,0,0,0.06);
                    border-radius: 12px;
                    background-color: #fff;
                    padding: 0 20px;
                    box-sizing: border-box;
                }
            }
        }
    }
    .expandMore {
        position: relative;
        margin-top: 35px;
        border: solid 2px rgba(0, 178, 148, 0.12);
        padding: 35px;
        border-radius: 8px;
        box-sizing: border-box;
        .courseHead {
            font-size: 26px;
            font-weight: 600;
        }
        .courseContent {
            margin-top: 25px;
            font-size: 17px;
            line-height: 28px;
            color: #555;
            text-align: justify;
        }
        .closeIcon {
            position: absolute;
            right: 5px;
            top: 5px;
            color: #ff5722;
            font-size: 28px;
            cursor: pointer;
        }
    }
}

button.registration {
    position: relative;
    overflow: hidden;
    display: block;
    width: 90%;
    background-color: #fff;
    margin: 35px auto 15px auto;
    margin-top: auto;
    padding: 6px 16px;
    font-size: 18px;
    --c: #ff582a;
    color: var(--c);
    border: 2px solid var(--c);
    font-weight: 600;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.5s;
    z-index: 1;
    &:focus {
        outline: none;
    }
    &.mini {
        width: 220px;
        margin: 0;
        margin-top: 35px;
    }
    span {
        position: absolute;
        width: 25%;
        height: 100%;
        background-color: var(--c);
        transform: translateY(150%);
        border-radius: 50%;
        left: calc((var(--n) - 1) * 25%);
        transition: 0.5s;
        transition-delay: calc((var(--n) - 1) * 0.1s);
        z-index: -1;
    }
    &:hover {
        color: #fff;
        span {
            transform: translateY(0) scale(2);
            &:nth-child(1) {
                --n: 1;
            }
            &:nth-child(2) {
                --n: 2;
            }
            
            &:nth-child(3) {
                --n: 3;
            }
            
            &:nth-child(4) {
                --n: 4;
            }
        }
    }
}

ul.dottedList {
    margin: 0;
    margin-top: 20px;
    font-size: 16.5px;
    li {
        position: relative;
        margin-top: 10px;
        list-style: none;
        span {
            color: #555;
        }
        &::before {
            content: "\2022";
            position: absolute;
            font-size: 14px;
            left: -20px;
            color: #00B294;
        }
    }
}

@media (max-width: 1280px) {
    .container {
        .list {
            flex-wrap: wrap;
            justify-content: space-evenly;
            .item {
                margin-top: 25px;
            }
        }
    }
}

@media (max-width: 720px) {
    .container {
        .list {
            .offer {
                .seperatedFlexItems {
                    flex-wrap: wrap;
                    .dottedList {
                        flex: 0 0 100%;
                        margin-right: 0;
                    }
                    .description {
                        flex: 0 0 100%;
                        margin-top: 25px;
                    }
                }
            }
        }
    }
}

@media (max-width: 470px) {
    button.registration.mini {
        width: 100%;
    }
}