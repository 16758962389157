
.wrapper {
    background: #eff3f8 url(../../assets/images/section1_bg.webp) top no-repeat;
    padding: 35px 0;
    margin-top: 35px;
    .container {
        max-width: 1280px;
        margin: 0 auto;
        padding: 0 25px;
        .title {
            position: relative;
            font-size: 32px;
            text-align: center;
            margin: 0 auto 25px auto;
            color: #242c7d;
            text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
        }
        .allTogether {
            display: flex;
            justify-content: space-between;
            .item {
                max-width: 200px;
                width: 100%;
                background: #fff;
                border-radius: 15px;
                min-height: 128px;
                padding: 10px 45px 10px 35px;
                // margin-bottom: 30px;
                position: relative;
                h5 {
                    font-size: 22px;
                    font-weight: bold;
                    margin: 0;
                    color: #00B294;
                }
                p {
                    color: #555;
                    font-size: 17.5px;
                    line-height: 27px;
                }
            }
        }
    }
}

@media (max-width: 1280px) {
    .wrapper {
        .container {
            .allTogether {
                flex-wrap: wrap;
                justify-content: space-evenly;
                .item {
                    margin-top: 25px;
                }
            }
        }
    }
}