.wrapper {
    background: #eff3f8 url(../../assets/images/section1_bg.webp) top no-repeat;
    padding-top: 5px;
    padding-bottom: 35px;
    margin-top: 35px;
    .content {
        max-width: 1280px;
        margin: 0 auto;
        padding: 0 35px;
        .title {
            font-size: 32px;
            text-align: center;
            color: #242c7d;
            font-weight: bold;
        }
        .sliderContainer {
            .item {
                width: 31%;
                border: solid 2px #f1f1f1;
                margin: 1%;
                border-radius: 8px;
                box-sizing: border-box;
                overflow: hidden;
                img {
                    width: 100%;
                }
            }
        }
    }
}