@import '../../assets//constants/colors.scss';

.container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 25px 35px;
    .title {
        font-size: 32px;
        text-align: center;
        color: #242c7d;
    }
    .info {
        display: flex;
        flex-direction: row;
        .images_photo {
            width: 300px;
            border-radius: 8px;
            box-shadow: 0px 0px 11px 4px rgba(0, 0, 0, 0.06);
        }
        .about {
            margin-left: 45px;
            font-size: 18px;
            padding-right: 100px;
            .name {
                font-size: 22px;
                font-weight: 600;
            }
            p {
                line-height: 30px;
                color: #444;
            }
        }
    }
}

@media (max-width: 1000px) {
    .container {
        .info {
            .images_photo {
                width: 125px;
            }
        }
    }
}

@media (max-width: 800px) {
    .container {
        .info {
            display: block;
            .images_photo {
                width: 100%;
            }
            .about {
                padding: 0;
                margin: 0;
                margin-top: 35px;
            }
        }
    }
}