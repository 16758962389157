.container {
    background-color: #fff;
    box-shadow: 0px 0px 11px 4px rgba(0, 0, 0, 0.06);
    padding: 25px;
    .goBack {
        display: flex;
        align-items: center;
        color: #00B294;
        text-decoration: none;
        font-size: 32px;
        .text {
            color: #222;
            font-size: 18px;
            margin-left: 10px;
            font-weight: bold;
        }
    }
}