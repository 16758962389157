.mainSectionContainer {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    .mainInfo {
        position: absolute;
        display: flex;
        align-items: center;
        left: 35px;
        top: 0;
        color: #00B294;
        width: 480px;
        height: 100vh;
        z-index: 2;
        h1 {
            font-size: 46px;
            font-weight: 700;
            line-height: 50px;
        }
        h2 {
            color: #555;
            font-size: 26px;
        }
        a {
            padding: 6px 16px;
            font-size: 28px;
            margin-top: 25px;
            font-weight: 700;
            text-align: left;
            cursor: pointer;
            --c: #ff582a;
            color: var(--c);
            border: 2px solid var(--c);
            border-radius: 6px;
            position: relative;
            background-color: #fff;
            overflow: hidden;
            text-decoration: none;
            display: inline-block;
            z-index: 1;
            transition: 0.5s;
            &:focus {
                outline: none;                
            }
            span {
                position: absolute;
                width: 25%;
                height: 100%;
                background-color: var(--c);
                transform: translateY(150%);
                border-radius: 50%;
                left: calc((var(--n) - 1) * 25%);
                transition: 0.5s;
                transition-delay: calc((var(--n) - 1) * 0.1s);
                z-index: -1;
            }
            &:hover {
                color: #fff;
                span {
                    transform: translateY(0) scale(2);
                    &:nth-child(1) {
                        --n: 1;
                    }
                    &:nth-child(2) {
                        --n: 2;
                    }
                    
                    &:nth-child(3) {
                        --n: 3;
                    }
                    
                    &:nth-child(4) {
                        --n: 4;
                    }
                }
            }
        }
    }
    .background {
        position: absolute;
        right: -1px;
        top: -1px;
        width: 70%;
        height: 100vh;
        overflow: hidden;
        z-index: 1;
    }
    .header {
        position: fixed;
        width: 100%;
        padding: 25px 0px;
        transition: 0.3s;
        z-index: 3;
        .container {
            margin: 0 auto;
            ul {
                margin: 0;
                padding: 0;
                font-weight: 400;
                font-size: 18px;
                text-align: right;
                li {
                    display: inline-block;
                    margin-right: 15px;
                    transition: 0.2s;
                    a {
                        color: #242c7d;
                        text-decoration: none;
                        user-select: none;
                    }
                    &:hover {
                        opacity: 0.6;
                    }
                }
            }
        }
        &.isScrolling {
            background-color: #fff;
            box-shadow: 0px 0px 11px 4px rgba(0, 0, 0, 0.06);
            transition: 0.4s;
        }
    }
}

@media (max-width: 1000px) {
    .mainSectionContainer {
        height: auto;
        .mainInfo {
            left: unset;
            top: unset;
            height: auto;
            position: relative;
            width: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
            a {
                margin-bottom: 35px;
            }
        }
        .background {
            background: #eff3f8 url(../../assets/images/section1_bg.webp) top no-repeat;
            background-size: cover;
            right: -0;
            top: -0;
            width: 100%;
            height: 100%;
            img {
                display: none;
            }
        }
        .header {
            display: none;
        }
    }
}
