.wrapper {
    width: 100%;
    padding: 35px 0;
    .container {
        max-width: 1280px;
        margin: 25px auto 0px auto;
        padding: 0 35px;
        .title {
            font-size: 32px;
            text-align: center;
            color: #242c7d;
            font-weight: bold;
        }
        .results {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 25px;
            .item {
                position: relative;
                width: 31%;
                background: #eff3f8 url(../../assets/images/section1_bg.webp) top no-repeat;
                background-size: contain;
                padding: 8px 12px;
                margin-top: 45px;
                border-radius: 8px;
                box-sizing: border-box;
                border: solid 2px rgba(0, 178, 148, 0.12);;
                .mainText {
                    font-size: 22px;
                    color: #00B294;
                    font-weight: bold;
                }
                .text {
                    margin-top: 15px;
                    font-size: 17px;
                    line-height: 28px;
                }
                .number {
                    position: absolute;
                    right: 15px;
                    top: -25px;
                    color: #00B294;
                    width: 50px;
                    height: 50px;
                    background-color: #eff3f8;
                    border-radius: 50%;
                    font-size: 24px;
                    border: solid 2px rgba(0, 178, 148, 0.12);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    svg path { stroke: #00B294; }
                }
            }
        }
    }
}

@media (max-width: 800px) {
    .wrapper {
        .container {
            .results {
                .item {
                    width: 100%;
                }
            }
        }
    }
}