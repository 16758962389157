.modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 999;
    .container {
        position: relative;
        width: 90%;
        height: auto;
        background-color: #fff;
        border-radius: 8px;
        padding: 25px;
        box-sizing: border-box;
        background: #eff3f8 url(../../assets/images/section1_bg.webp) top no-repeat;
        box-shadow: none;
        background-size: contain;
        overflow: auto;
        border: solid 2px rgba(0, 178, 148, 0.12);
        transition: 0.3s;
        .icon {
            position: absolute;
            right: 5px;
            top: 5px;
            font-size: 32px;
            color: #ff5722;
            transition: 0.2s;
            cursor: pointer;
            &:hover {
                opacity: 0.7;
                transition: 0.3s;
            }
        }
        .text {
            .title {
                font-size: 26px;
                font-weight: 700;
                color: #00B294;
            }
            .simple {
                margin-top: 25px;
                font-size: 18px;
                line-height: 24px;
                color: #555;
                li {
                    position: relative;
                    list-style: none;
                    margin-left: 35px;
                    margin-top: 8px;
                    &:before {
                        content: "\2022";
                        position: absolute;
                        font-size: 14px;
                        left: -20px;
                        color: #00B294;
                    }
                }
            }
            .payments {
                margin-top: 25px;
                margin-bottom: 25px;
                a {
                    border: solid 2px #00B294;
                    text-decoration: none;
                    padding: 6px 12px;
                    border-radius: 8px;
                    color: #00B294;
                    font-weight: bold;
                    font-size: 20px;
                    margin-right: 35px;
                    background-color: #fff;
                    // opacity: 0.8;
                    transition: 0.2s;
                    &:hover {
                        color: #fff;
                        background-color: #00B294;
                        transition: 0.3s;
                    }
                }
            }
            .red {
                color: #ff5722;
                font-size: 18px;
                font-weight: 600;
                margin: 20px 10px;
            }
            .pay {
                text-align: left;
                & > a {
                    display: inline-block;
                    color: #fff;
                    text-decoration: none;
                    padding: 5px 16px;
                    margin-right: 15px;
                    margin-top: 10px;
                    border-radius: 6px;
                    font-size: 13.5px;
                    &.telega {
                        background-color: #0088cc;
                    }
                    &.mail {
                        background-color: rgba(199, 22, 16, 0.7);
                    }
                    &.viber {
                        background-color: #665CAC;
                    }
                    svg {
                        transform: translateY(2px);
                    }
                }
                .tiny {
                    margin-top: 20px;
                    font-size: 13px;
                    a {
                        color: #00B294;
                    }
                }
            }
        }
    }
}

@media (max-width: 1280px) {
    .modal {
        .container {
            height: 90%;
        }
    }
}

@media (max-width: 800px) {
    .modal {
        display: flex;
        align-items: center;
        
        .container {
            width: 90%;
            padding: 8px;
            .text {
                .title {
                    padding-top: 30px;
                }
                .simple {
                    font-size: 16px;
                    line-height: 20px;
                    li {
                        margin-left: 20px;
                    }
                }
                .pay {
                    text-align: left;
                }
                .payments {
                    a {
                        display: block;
                        &:last-child {
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
    }
}