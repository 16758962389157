.mainContent {
    margin: 0px auto;
    padding: 15px;
    max-width: 900px;
    line-height: 30px;
    font-size: 18px;
    height: calc(100vh - 82px);
    overflow: auto;
    box-sizing: border-box;
    h2 {
        text-align: center;
    }
}